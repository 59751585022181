@import "@/styles/_variables.scss";







































































































































































































































.uk-width-small {
  width: 215px;
}

.uk-height-small {
    height: 160px;
}

.selected {
  border: 1px solid var(--app-primary-color);
}

.more-options {
  padding: 5px 0px;
}

.meta-data {
  font-size: 10px;
}

.title {
  font-size: 14px;
}
