/*
  Theme Configuration File

 Change various SCSS related variables and options here
 Use ~@/assets prefix for loading images from relative urls
 Ex: background-image: url('~@/assets/foo.jpg');
*/

/* Theme Options */

// Default Dark Theme
:root {
  --app-background-color: #131319;
  --app-primary-color: #9040ff;
  --app-secondary-color: #1e1e29aa;
  --app-modal-color: #252525;
  --white-color: #ededed;
  --black-color: #131313;
  --text-color: #dbdbdb;
  --muted-text-color: #545454;
  --app-success-color: #057f52;
  --app-warning-color: #de8b4f;
  --app-danger-color: #8a0000;
  --app-start-color: #4097ff;
  --app-end-color: #9040ff;
  --sidebar-width: 15em;
  --shadow-color: #23232399;
  --border-color: #54545440;
  --border-color-gradient: radial-gradient(circle at 0 100%, var(--app-start-color), var(--app-end-color)); 
  --app-background-color-gradient: radial-gradient(circle at 0 100%, var(--app-start-color), var(--app-end-color));
  --app-highlight-color: #4097ff20;
  --app-highlight-color-gradient: radial-gradient(circle at 0 100%,#4097ff10,#9040ff10);
  --app-notification-color: #232332ee;
  --app-clear-color: #13131322;
  --app-input-bg: #131319;
}

// Light Theme
[theme="light"] {
  --white-color: #ededed;
  --app-background-color: #fefefe;
  --app-primary-color: #9040ff;
  --app-secondary-color: #ebecf5;
  --app-modal-color: #ffffff;
  --text-color: #232323;
  --muted-text-color: #ababab;
  --app-start-color: #4097ff;
  --app-end-color: #9040ff;
  --sidebar-width: 15em;
  --shadow-color: #23232399;
  --border-color: #54545440;
  --border-color-gradient: radial-gradient(circle at 0 100%, var(--app-start-color), var(--app-end-color)); 
  --app-background-color-gradient: radial-gradient(circle at 0 100%, var(--app-start-color), var(--app-end-color));
  --app-highlight-color: #edededdd;
  --app-highlight-color-gradient: radial-gradient(circle at 0 100%,#4097ff10,#9040ff10);
  --app-notification-color: #b8b8c8ee;
  --app-clear-color: #cacaca44;
  --app-input-bg: #FEFEFE;
}
