@import "@/styles/_variables.scss";
































































































































































































































































































































































































































































































































































































































































































































































































































































.icons {
    width: 35px;
}

.format {
    border-top: 1px solid var(--app-primary-color);
    border-left: 1px solid var(--app-primary-color);
    border-right: 1px solid var(--app-primary-color);
    background-color: white;
}

.format:last-child {
  border-bottom: 1px solid var(--app-primary-color);
}

.iconic-player {
    width: 600px;
    height: 365px;
}

.name-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.media-header-text .uk-icon {
    line-height: normal;
}
